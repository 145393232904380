@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;900&display=swap");

a {
  transition: all 0.25s ease;
}

.ccs-page-title {
  font-family: "Poppins";
  h5 {
    font-size: 1em;
    font-weight: 700;
  }
  h1 {
    font-size: 5em;
    font-weight: 700;
    letter-spacing: -3px;
  }
}

.staking-app {
  border-radius: 40px;
  background-color: #f7f9fb;
  border: 1px solid #d7d7d7;
  font-family: "Poppins", sans-serif;
  .card,
  .card-header {
    border-radius: 0;
    border: none;
    display: flex;
    align-items: center;
    margin-top: -1px;
  }
  .card {
    background-color: transparent;
    flex-wrap: wrap;
  }
  .card-body {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    height: 100%;
    flex-grow: 0;
  }
  .card-header {
    border-radius: 40px;
    position: relative;
    z-index: 1;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  h5.card-header,
  h3.card-body {
    font-size: 0.9em;
    margin-bottom: 0;
    font-weight: 600;
    letter-spacing: 0.5px;
    height: 67px;
    display: flex;
    align-items: center;
  }

  .ccs-connect-wallet {
    border-radius: 0 30px 0 30px;
    background: #49c3fb;
    .btn {
      width: 100%;
      background: none;
      border: none;
      color: #020b0e;
      text-transform: uppercase;
      min-height: 90px;
      font-weight: 700;
      letter-spacing: 0.5px;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.information-message {
  background-color:#49c3fb;
  border-radius: 5px;
  margin-bottom:3rem;
  
  a {
    color:#000;
    font-weight: 700;
  }
}

.ccs-staking-inner {
  .card-header,
  .form-control {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .form-control {
    border: none;
    border-radius: 40px;
    color: #020b0e;
    font-weight: 600;
    min-height: 67px;
    &::placeholder {
      color: #020b0e;
      font-weight: 700;
    }
  }
  .card-body,
  .form-control {
    border: 1px solid #d7d7d7;
  }
}

.ccs-staking-row {
  .card-body {
    overflow-x: hidden;
    position: relative;
  }
}

.connect-wallet-btn {
  position: relative;
  &::after {
    content: " ";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #e8e8e8;
    border-radius: 50%;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.ccs-staking-row {
  .card-body {
    background-color: #fff;
    margin-left: -40px;
    padding-left: 60px;
    flex-grow: 1;
  }
}

.card-header {
  background: #020b0e;
  color: #fff;
}

.ccs-action {
  height: 100%;
  padding: 0;
  border: none;
  background: none;
  flex-grow: 1;
  .btn {
    background: #49c3fb;
    border-radius: 40px;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5px;
    height: 67px;
    transition: all 0.25s ease;
    &:hover {
      background: #020b0e;
    }
  }
}

.switch-network {
  font-weight: 700;
  font-size: 0.9em;
}

#cc-header {
  border-bottom: 1px solid #020b0e;
}

a.buy-cta {
  background: #020b0e;
  color: $white;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  padding: 1rem 2.5rem;
  border-radius: 50px;
  margin: 0;
  &:hover {
    background: lighten(#020b0e, 10%);
  }
}

#cc-footer {
  background-color: #f7f9fb;
  margin-top: 50px;
  img {
    border-bottom: 1px solid #191818;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  .cc-footer-logo:last-of-type img {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  a {
    transition: all 0.25s ease;
    &:hover {
      opacity: 0.7;
    }
  }
}

#cc-footermenu {
  color: #fff;
  text-align: center;
  background-color: #191818;
  .col-md-3 {
    border-bottom: 2px solid #49c3fb;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
    transition: all 0.25s ease;
    &:hover {
      opacity: 0.7;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    &:last-of-type {
      margin-bottom: 0;
    }
    li {
      a {
        display: inline-flex;
        align-items: center;
      }
      img {
        width: 18px;
        height: 18px;
        object-fit: contain;
        margin-right: 0.5rem;
      }
    }
  }
}

.footer-logo {
  max-width: 180px;
}

.cc-logo {
  max-width: 300px;
}

@media (max-width: 767px) {
  .break {
    flex-basis: 100%;
    height: 0;
  }
}

.ccs-staking-inner {
  padding: 1rem 2rem 2rem 2rem;
}

@media (min-width: 768px) {
  .ccs-staking-inner {
    padding: 3rem 4rem;
  }
  .ccs-action {
    &.stake-withdraw {
      padding-left: 55px;
      margin-left: -40px;
    }
  }
  .switch-network {
    padding-left: 1rem;
    font-size: 1em;
  }
  #cc-footer {
    .cc-footer-logo {
      border-right: 1px solid #191818;
      border-bottom: none;
      &:last-of-type {
        border-right: none;
      }
    }
    img {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  #cc-footermenu {
    font-size: 0.8em;
    text-align: left;
    .col-md-3 {
      border-left: 2px solid #49c3fb;
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
      &:first-of-type {
        border-left: none;
      }
    }
  }
  .cc-logo {
    max-width: 100%;
  }
  .footer-logo {
    max-width: 140px;
  }
}

@media (min-width: 992px) {
  .ccs-page-title {
    position: relative;
    &::before {
      content: " ";
      position: absolute;
      top: 0.25rem;
      left: -4rem;
      height: 550px;
      width: 5px;
      display: block;
      background: #49c3fb;
    }
  }
  .break {
    display: none;
  }
  .staking-app {
    h5.card-header,
    h3.card-body {
      font-size: 1em;
    }
  }
  .ccs-staking-row .card-body {
    flex-grow: 0;
  }
  .ccs-action {
    padding-left: 1rem;
    min-width: 190px;
  }
  .enter-amount {
    flex-grow: 1;
  }
  #cc-footer {
    margin-top: 100px;
  }
  #cc-footermenu {
    font-size: 1em;
    ul {
      padding-left: 1rem;
    }
  }
  .footer-logo {
    max-width: 150px;
  }
}

@media (min-width: 1200px) {
  .ccs-staking-inner {
    padding: 4rem 5rem;
  }
  .cc-logo {
    max-width: 75%;
  }
  #cc-footer {
    margin-top: 150px;
  }
}

@media (min-width: 1400px) {
  .ccs-action {
    min-width: 224px;
  }
}
